var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "previewPrint" }, [
    _c("iframe", {
      staticStyle: { width: "100%", height: "100%" },
      attrs: { id: _vm.previewId, src: _vm.previewHtmlUrl, frameborder: "0" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }